import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import Footer from "components/Footer.js";
import GridContainer from "components/GridContainer.js";
import GridItem from "components/GridItem.js";
import Parallax from "components/Parallax.js";

// import brandLogo from "images/logo_transparent.png";
import brandLogoBlack from "images/logo_transparent_black.png";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import stylesProduct from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStylesProduct = makeStyles(stylesProduct);

export default function PirateWars(props) {
  const classes = useStyles();
  const classesProduct = useStylesProduct();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={brandLogoBlack} alt="PBlogo" 
               style={{height: "45px", backgroundColor: "rgb(255,255,255,0.4)", 
                       boxShadow: "0 0 7px 5px rgba(255,255,255,0.4)",
                      //  boxShadow: "0 0 15px 10px rgba(255,255,255,0.4)",
                       borderRadius: "25px"}} />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("images/pw-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>Pirate Wars</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classesProduct.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <h2 className={classesProduct.title}>Application overview</h2>
                <h5 className={classesProduct.description}>
                  Pirate Wars ia a stylized version of the game of <i>Battleships</i>, 
                  also commonly known as <i>Sea Battle</i>. User plays versus computer.
                  Rails API backend for persisting players data and managing leaderboard.
                  Vanilla JavaScript for the frontend, game logic and AI.
                  Bootstrap and custom CSS for styling and responsive design, 
                  user can play the game on a desktop, tablet or mobile phone.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <a href="https://github.com/paul-b19/pirate-wars-js-frontend" target="_blank" rel="noopener noreferrer">
                  <h4 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fab fa-github"} /> Frontend
                  </h4>
                </a>
                <ul className={classesProduct.description} style={{textAlign: "left"}}>
                  <li>JavaScript</li>
                  <li>jQuery</li>
                  <li>Bootstrap</li>
                  <li>Heroku</li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <a href="https://github.com/paul-b19/pirate-wars-rails-api" target="_blank" rel="noopener noreferrer">
                  <h4 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fab fa-github"} /> Backend
                  </h4>
                </a>
                <ul className={classesProduct.description} style={{textAlign: "left"}}>
                  <li>Rails API</li>
                  <li>PostgreSQL</li>
                  <li>Heroku</li>
                </ul>
              </GridItem>
            </GridContainer>
            <br/>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <iframe style={{minHeight: "350px"}} width="100%" height="100%" 
                        src="https://www.youtube.com/embed/nUkOPGgQg3Q" 
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; 
                        gyroscope; picture-in-picture; fullscreen" title="demo">
                </iframe>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <a href="http://pirate-wars.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                  <h3 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fas fa-hand-point-right"} /> http://pirate-wars.herokuapp.com
                  </h3>
                </a>
                <h4 className={classesProduct.description}>
                  <b>Note:</b> I use a free heroku account, 
                  it may take some time to start the application.  
                  Thank you for your patience!
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
