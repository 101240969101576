import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";

// core components
import Header from "components/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import Footer from "components/Footer.js";
import GridContainer from "components/GridContainer.js";
import GridItem from "components/GridItem.js";
import Parallax from "components/Parallax.js";

// import brandLogo from "images/logo_transparent.png";
import brandLogoBlack from "images/logo_transparent_black.png";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import stylesProduct from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStylesProduct = makeStyles(stylesProduct);

export default function Postulante(props) {
  const classes = useStyles();
  const classesProduct = useStylesProduct();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={brandLogoBlack} alt="PBlogo" 
               style={{height: "45px", backgroundColor: "rgb(255,255,255,0.4)", 
                       boxShadow: "0 0 7px 5px rgba(255,255,255,0.4)",
                      //  boxShadow: "0 0 15px 10px rgba(255,255,255,0.4)",
                       borderRadius: "25px"}} />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("images/postulante-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>Postulante API Client</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* <ProductSection /> */}
          <div className={classesProduct.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <h2 className={classesProduct.title}>Application overview</h2>
                <h5 className={classesProduct.description}>
                  Postulante (<i>from Latin “upon request”</i>) is a simple API client 
                  with intuitive user interface and that helps to bypass some operations 
                  when developing a web application. User can create and send requests, 
                  edit it and create collections of requests. Frontend built with Bootstrap 
                  and custom CSS for fully responsive design.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <a href="https://github.com/paul-b19/postulante-react-frontend" target="_blank" rel="noopener noreferrer">
                  <h4 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fab fa-github"} /> Frontend
                  </h4>
                </a>
                <ul className={classesProduct.description} style={{textAlign: "left"}}>
                  <li>React.js</li>
                  <li>Redux</li>
                  <li>Material-UI: material-table</li>
                  <li>React-Ace</li>
                  <li>stringify-object, dirty-json packages</li>
                  <li>Bootstrap</li>
                  <li>Font Awesome</li>
                  <li>Heroku</li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <a href="https://github.com/paul-b19/postulante-rails-backend" target="_blank" rel="noopener noreferrer">
                  <h4 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fab fa-github"} /> Backend
                  </h4>
                </a>
                <ul className={classesProduct.description} style={{textAlign: "left"}}>
                  <li>Rails</li>
                  <li>PostgreSQL</li>
                  <li>Heroku</li>
                </ul>
              </GridItem>
            </GridContainer>
            <br/>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <iframe style={{minHeight: "350px"}} width="100%" height="100%" 
                        src="https://www.youtube.com/embed/ShQBZoS115Q" 
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; 
                        gyroscope; picture-in-picture; fullscreen" title="demo">
                </iframe>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <a href="http://postulante.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                  <h3 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fas fa-hand-point-right"} /> http://postulante.herokuapp.com
                  </h3>
                </a>
                <h4 className={classesProduct.description}>
                  <b>Note:</b> I use a free heroku account, 
                  it may take some time to start the application. 
                  If an error occurred during a first start, just reload the page. 
                  Thank you for your patience!
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
