import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/GridContainer.js";
import GridItem from "components/GridItem.js";
import Card from "components/Card.js";

import postulanteBg from "images/postulante-bg.jpg";
import ocritBg from "images/ocrit-bg.jpg";
import pwBg from "images/pw-bg.jpg";
import dpcBg from "images/dpc-bg.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <Link to="/postulante">
                  <img src={postulanteBg} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h2 style={{color: "darkorchid", textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>
                      <b>Postulante API Client</b>
                    </h2>
                  </div>
                </Link>
                <Link to="/ocrit">
                  <img src={ocritBg} alt="Second slide" className="slick-image" />
                  <div className="slick-caption">
                    <h2 style={{color: "darkorchid", textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>
                      <b>OCR-it!</b>
                    </h2>
                  </div>
                </Link>
                <Link to="/piratewars">
                  <img src={pwBg} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                    <h2 style={{color: "darkorchid", textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>
                      <b>Pirate Wars</b>
                    </h2>
                  </div>
                </Link>
                <Link to="/deadpoetscohort">
                  <img src={dpcBg} alt="Forth slide" className="slick-image" />
                  <div className="slick-caption">
                    <h2 style={{color: "darkorchid", textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>
                      <b>Dead Poets Cohort</b>
                    </h2>
                  </div>
                </Link> 
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
