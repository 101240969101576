import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import Footer from "components/Footer.js";
import GridContainer from "components/GridContainer.js";
import GridItem from "components/GridItem.js";
import Parallax from "components/Parallax.js";

// import brandLogo from "images/logo_transparent.png";
import brandLogoBlack from "images/logo_transparent_black.png";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import stylesProduct from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStylesProduct = makeStyles(stylesProduct);

export default function PirateWars(props) {
  const classes = useStyles();
  const classesProduct = useStylesProduct();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={brandLogoBlack} alt="PBlogo" 
               style={{height: "45px", backgroundColor: "rgb(255,255,255,0.4)", 
                       boxShadow: "0 0 7px 5px rgba(255,255,255,0.4)",
                      //  boxShadow: "0 0 15px 10px rgba(255,255,255,0.4)",
                       borderRadius: "25px"}} />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("images/dpc-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{textShadow: "3px 3px 3px rgba(156,38,176,0.4)"}}>Dead Poets Cohort</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classesProduct.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <h2 className={classesProduct.title}>Application overview</h2>
                <h5 className={classesProduct.description}>
                  This is my first real application! 
                  With this app user can search for poems by title/author, 
                  add poems to a collection, turn on classical music on a background 
                  and enjoy their favorite poetry. 
                  We utilized PoetryDB API to seed a database with poems 
                  and built a user interface using TTY::Prompt and Catpix gems.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <a href="https://github.com/paul-b19/guided-module-one-final-project-nyc-web-102819" target="_blank" rel="noopener noreferrer">
                  <h4 className={classesProduct.title}>
                    <i className={classes.socialIcons + " fab fa-github"} /> Command line interface
                  </h4>
                </a>
                <ul className={classesProduct.description} style={{textAlign: "left", listStyle: "none", paddingLeft: "33%"}}>
                  <li>Ruby</li>
                  <li>PoetryDB API</li>
                  <li>TTY::Prompt</li>
                  <li>Catpix gem</li>
                  <li>Background music</li>
                </ul>
              </GridItem>
            </GridContainer>
            <br/>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={9}>
                <iframe style={{minHeight: "350px"}} width="100%" height="100%" 
                        src="https://www.youtube.com/embed/zjsAurrPS3U" 
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; 
                        gyroscope; picture-in-picture; fullscreen" title="demo">
                </iframe>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
